// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, useRef, useLayoutEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Collapse,
    Stack,

    Button,
    Pagination,
    CircularProgress,
    
    Alert,

    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,    

    Fab,

    Fade,
} from '@mui/material/'
// --------------------------------------------------------- MUI ICONS --
import {
    CheckCircle as CheckCircleIcon,
    Download as DownloadIcon,
    GridView as GridViewIcon,
    Error as ErrorIcon,        
} from '@mui/icons-material/'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import CheckIcon from '@mui/icons-material/Check';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// ----------------------------------------------------------------------
// --------------------------------------------------------- REACT-PDF --
import { Document, Page, pdfjs } from 'react-pdf'
import useResizeObserver from '@react-hook/resize-observer'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import CrossFade from './crossfade'
// ----------------------------------------------------------------------
// --------------------------------------------------------- WORKER -----
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONSTS -----
const API_URL = "/api/resume/cv"

const SECTIONID = "template"

const useWidth = (target) => {
    const [width, setWidth] = useState(null);
  
    useLayoutEffect(() => {
      setWidth((target.current.getBoundingClientRect().width)+20)      
    }, [target])
  
    useResizeObserver(target, (entry) => {
        setWidth((entry.contentRect.width)+20)    
    })

    // console.log (height)
    return width
}

export default (props) => {       
    const {appState, editorState, handleSave, defaultData, callbackOnChange} = props

    const [previewState, setPreviewState] = useState({
        show: false,

        currentPage: 1,
        totalPageCount: null,        
        
        currentImg: null,

        scale: 2.0,
        renderTextLayer: false,
        renderAnnotationLayer: false,

        error: false,
        errorText: ""
      })
      const isMounted = useRef(false)

      useEffect(() => {
        if (isMounted.current) {                        
            setFormData({...defaultData})
        } else {            
            isMounted.current = true
        }    
    }, [defaultData])

    const formDataDefaults = {
        id: "",
        mainColor: "",
        fontSize: ""
    }
    
    const [formData, setFormData] = useState(formDataDefaults)
    
    useEffect(() => {
        callbackOnChange(SECTIONID, formData)
    }, [formData])
    

    const previewImg = useRef(null)
    
    const width = useWidth(previewImg)    
    
    const handleOnLoadSuccess = (page) => {
        setPreviewState({...previewState, totalPageCount: page.numPages})
    }

    const handlerOnLoadError = () => {
        setPreviewState({...previewState, show: false, error: true, errorText: "Der opstod en ukendt fejl!"})
    }

    const handleOnRenderSuccess = () => {
        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')
        setPreviewState({...previewState, currentImg: canvas[0].toDataURL('image/png'), show: true})
    }    
      
    const handleOnPaginationChange = (event, value) => {
        setPreviewState({...previewState, currentPage: value})
    }

    const preventDragHandler = (event) => {
        event.preventDefault();
    }

    const downloadFile = () => {        
        
        const link = document.createElement('a');
        link.href = `${API_URL}/${appState.cvId}/preview/?key=${appState.cvKey}&reload=${editorState.reloadPreview}`;
        link.setAttribute(
            'download',
            `cv.pdf`,
        );
      
        // Append to html link element page
        document.body.appendChild(link);
      
        // Start download
        link.click();
      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
     
    const handleOnClickSpeedDial = (e, operation) => {
        e.preventDefault()

        if (operation=="save") {
            
            handleSave()
        } else if (operation=="download") {
            downloadFile()
        }
        
    }

    const actions = [        
        { icon: <SaveIcon />, name: 'Gem', operation: "save" },
        { icon: <DownloadIcon />, name: 'Download', operation: "download" },
    ]

    const theme = createTheme({
        palette: {
          hkjb1: {
            main: '#65bd7d',  
            dark: '#73d68e'
          },
          hkjb2: {
            main: '#198fd9',  
            dark: '#1ca0f3'
          },
          hkjb3: {
            main: '#ec8969',  
            dark: '#ff9874'
          },
          hkjb4: {
            main: '#90d9d4', 
            dark: '#a1f3ed' 
          },
          hkjb5: {
            main: '#1fabb3', 
            dark: '#23c3cd'
          }
        },
      });

    const handleOnMainColorChange = (value) => {
        setFormData({...formData, mainColor: value})

    }

    return (  
        <>
            <Collapse in={previewState.error}>
                <Alert variant="filled" severity="error">
                    {previewState.errorText}
                </Alert>
            </Collapse>
            <SpeedDial
                ariaLabel="Preview menu"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
               
                icon={<CrossFade 
                    components={[
                        {
                            in: editorState.saving,
                            component: <CircularProgress sx={{color: 'white'}} size="20px" thickness="4"/>
                        },
                        {
                            in: (!editorState.saving && !editorState.error),
                            component: <SpeedDialIcon/>,
                        },
                        {
                            in: editorState.error,
                            component: <ErrorIcon sx={{color: 'white'}}></ErrorIcon>,
                        }
                    ]}
                />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={(e) => {
                            handleOnClickSpeedDial(e, action.operation)
                       }}
                    />
                ))}
            </SpeedDial> 

            <Box
                sx={{
                    display:'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    alignItems: 'center', 
                    justifyContent:'center',
                    padding: '75px',
                    backgroundColor: '#78909c'
                }}
            >      
                <Fade in={previewState.show}>
                    <Box sx={{
                        width: width, 
                        marginBottom: '10px' 
                        }}
                    >
                        <Grid container>
                            <Grid item xs={10}>
                                <Stack spacing={2} direction="row">
                                <ThemeProvider theme={theme}>
                                
                                <Fab aria-label="add" color={"hkjb1"} size="small" onClick={(e) => {handleOnMainColorChange("green")}}>
                                    {formData.mainColor == "green" ? <CheckIcon sx={{color: "white"}}/> : <></> }
                                </Fab>
                                <Fab aria-label="add" color={"hkjb2"} size="small" onClick={(e) => {handleOnMainColorChange("blue")}}>
                                    {formData.mainColor == "blue" ? <CheckIcon sx={{color: "white"}}/> : <></> }
                                </Fab>
                                <Fab aria-label="add" color={"hkjb3"} size="small" onClick={(e) => {handleOnMainColorChange("orange")}}>
                                    {formData.mainColor == "orange" ? <CheckIcon sx={{color: "white"}}/> : <></> }
                                </Fab>
                                <Fab aria-label="add" color={"hkjb4"} size="small" onClick={(e) => {handleOnMainColorChange("mint")}}>
                                    {formData.mainColor == "mint" ? <CheckIcon sx={{color: "white"}}/> : <></> }
                                </Fab>
                                <Fab aria-label="add" color={"hkjb5"} size="small" onClick={(e) => {handleOnMainColorChange("turquoise")}}>
                                    {formData.mainColor == "turquoise" ? <CheckIcon sx={{color: "white"}}/> : <></> }
                                </Fab>

                                </ThemeProvider>
                                    {/* <Button 
                                        variant="contained" 
                                        target="_blank" 
                                        download={"cv.pdf"} href={`${API_URL}/${appState.cvId}/preview/?key=${appState.cvKey}&reload=${editorState.reloadPreview}`}
                                        startIcon={<DownloadIcon />}
                                        disabled={false} 
                                    >
                                        Download PDF
                                    </Button>                          
                                    <Button 
                                        variant="text" 
                                        startIcon={<GridViewIcon/>} 
                                        sx={{
                                            color: 'white'
                                        }} 
                                        disabled={true}
                                    >
                                        Vælg skabelon
                                    </Button>                                     */}
                                </Stack>     
                                
                            </Grid>
                            <Grid item xs={2} justify="flex-end">
                                {/* <Box display="flex" justifyContent="flex-end" > 
                                    <CrossFade 
                                        components={[
                                            {
                                                in: editorState.saving,
                                                component: <CircularProgress sx={{color: 'white', ml: '2px', mt: '2px'}} size="20px" thickness="8"/>
                                            },
                                            {
                                                in: (!editorState.saving && !editorState.error),
                                                component: <CheckCircleIcon sx={{color: 'white'}}></CheckCircleIcon>,
                                            },
                                            {
                                                in: editorState.error,
                                                component: <ErrorIcon sx={{color: 'white'}}></ErrorIcon>,
                                            }
                                        ]}
                                    />
                                </Box>                                              */}
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>

                <Fade in={previewState.show}>
                    <Box ref={previewImg}
                        component="img"                
                        sx={{                            
                            backgroundColor: 'white', 
                            border: 1, 
                            borderRadius: 2, 
                            borderColor: 'white',
                            padding: '10px',                                         
                            maxWidth: '100%',      
                            maxHeight: '100%'              
                        }}
                        onDragStart={preventDragHandler}                        
                        src={previewState.currentImg}
                    />                   
                </Fade>
                
                <Fade in={previewState.show}>
                    <Box 
                        display="flex"
                        justifyContent="center"
                        sx={{                            
                            marginTop: '10px',
                            width: width, 
                        }}
                    >
                        <Pagination 
                            count={previewState.totalPageCount} 
                            page={previewState.currentPage} 
                            shape="rounded" 
                            sx={{
                                color: 'white'
                            }} 
                            onChange={handleOnPaginationChange}
                        />
                    </Box>                    
                </Fade>                
            </Box>     

            <Box sx={{display: "none"}}>
                <Document
                    file={`${API_URL}/${appState.cvId}/preview/?key=${appState.cvKey}&reload=${editorState.reloadPreview}`}
                    onLoadSuccess={handleOnLoadSuccess}
                    onLoadError={handlerOnLoadError}
                >
                    <Page                    
                        scale={previewState.scale}
                        pageNumber={previewState.currentPage}
                        renderTextLayer={previewState.renderTextLayer}
                        renderAnnotationLayer={previewState.renderAnnotationLayer}
                        onRenderSuccess={handleOnRenderSuccess}
                    />
                </Document>                    
            </Box>           
        </>   
    )
}