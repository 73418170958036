// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Grid,
    Paper,
    Collapse,
    Fade,

    Alert,
} from '@mui/material/'
// --------------------------------------------------------- MUI ICONS --
import {
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import Details from './details/'
import Profile from './profile/'
import Employment from './employment/'
import Education from './education/'
import Skills from './skills/'
import Language from './language'
import Courses from './courses/'
import References from './references/'
import Links from './links/'
import Hobbies from './hobbies/'

import Preview from './preview'

import './quill.css'
import './mui.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const API_URL = "/api/resume/cv"
const editorDataDefaults = {
        details: {
            sectionTitle: "Personlige detaljer",

            jobTitle: "",

            firstName: "",
            lastName: "",
            email: "",
            phone: "",

            address: "",
            postcode: "",
            city: "",          
            
            birthDate: "",
            showBirthDate: false,

            avatar: ""
        },

        profile: {
            sectionTitle: "Profil",
            text: ""
        },

        employment: {
            sectionTitle: "Ansættelser",
            items: []
        },

        education: {
            sectionTitle: "Uddannelse",
            items: []
        },

        skills: {
            sectionTitle: "Kompetancer",
            items: []
        },

        courses: {
            sectionTitle: "Kurser",
            items: []
        },

        links: {
            sectionTitle: "Links",
            items: []
        },

        language: {
            sectionTitle: "Sprog",
            items: []
        },

        references: {
            sectionTitle: "Referencer",
            items: []
        },

        hobbies: {
            sectionTitle: "Hobbyer",
            text: ""            
        },

        userDefinedSection: {
            sectionTitle: "",
            items: []
        }
}
// ----------------------------------------------------------------------
// --------------------------------------------------------- Vars -------
var TIMEOUT_SAVE = null

export default (props) => {       
    const {appState, setAppState} = props
    const isMounted = React.useRef(null)

    const editorStateDefaults = {
        isInitializing: true,
        
        disabled: false,                

        saving: false,
        reloadPreview: 0,
        
        error: false,    
        errorText: "",
    }  

    const [editorState, setEditorState] = useState(editorStateDefaults)
    const [defaultData, setDefaultData] = useState(editorDataDefaults)
    
    let currentData = {}    

    useEffect(() => {
        fetch(`${API_URL}/${appState.cvId}?key=${appState.cvKey}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }            
        })           
        .then(data=>{                
            return data.json ()
        })                
        .then((data) => {                               
            if (data.error == null) {
                setDefaultData(data.data)                
                setEditorState({ ...editorState, isInitializing: false })
            } else {              
                throw new Error (data.error.code)                
            }
        })            
        .catch((error) => {
            handleError (error)
        })            
    }, [])

    useEffect(() => {
        currentData = {...defaultData}        
    }, [defaultData])
        
    const handleSave = () => {
        setEditorState({ ...editorState, saving: true })
        fetch(`${API_URL}/${appState.cvId}?key=${appState.cvKey}`, {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',                
            },
            body: JSON.stringify(currentData),
        })
        .then((data) => {
            if (data.ok) {                
                setEditorState({ ...editorState, saving: false, reloadPreview: (editorState.reloadPreview + 1) })
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null)
                throw new Error (data.error.code)                
        })
        .catch((error) => {     
            handleError (error)
        })            
    }
    
    const handleError = (error) => {        
        setEditorState({ ...editorState, error: true, errorText: "Der opstod en ukendt fejl." })     
    }

    const doSave = () => {
        if (!editorState.isInitializing) {
            if (!editorState.saving)                     
                setEditorState({ ...editorState, saving: true })

            clearTimeout(TIMEOUT_SAVE)
        TIMEOUT_SAVE = setTimeout(handleSave, 800)    
        }
        
    }

    const callbackOnChange = (section, data) => {        
        // if (isMounted.current) {

        doSave()
            

            currentData = {...currentData, [section]: data }
        // } else {
        //     isMounted.current = true
        // }
    }
      
    return (    
                   
            <Grid                    
                container 
                sx={{ 
                    height: '100%' 
                }}
            >
                <Fade in={!editorState.isInitializing}>         
                <Grid 
                    item xs={6} 
                    sx={{ 
                        height: '100%', 
                        overflow: 'scroll' 
                    }}
                >       
                
                    <Paper 
                        elevation={3} 
                        sx={{
                            p: 5, 
                            minHeight: 'calc(100vh)' 
                        }}
                    >
                        
                        <Collapse in={editorState.error}>
                            <Alert 
                                variant="filled" 
                                severity="error"
                            >
                                {editorState.errorText}
                            </Alert>
                        </Collapse>

                        <Details editorState={editorState} setEditorState={setEditorState} defaultData={defaultData.details} callbackOnChange={callbackOnChange}/>                    
                        <Profile editorState={editorState} defaultData={defaultData.profile} callbackOnChange={callbackOnChange}/>
                        
                        <Employment editorState={editorState} defaultData={defaultData.employment} callbackOnChange={callbackOnChange}/>
                        <Education editorState={editorState} defaultData={defaultData.education} callbackOnChange={callbackOnChange}/>
                        
                        <Skills editorState={editorState} defaultData={defaultData.skills} callbackOnChange={callbackOnChange}/>                    
                        <Courses editorState={editorState} defaultData={defaultData.courses} callbackOnChange={callbackOnChange}/>
                        
                        <Language editorState={editorState} defaultData={defaultData.language} callbackOnChange={callbackOnChange}/>
                        <References editorState={editorState} defaultData={defaultData.references} callbackOnChange={callbackOnChange}/>
                        
                        <Links editorState={editorState} defaultData={defaultData.links} callbackOnChange={callbackOnChange}/>                                       
                        <Hobbies editorState={editorState} defaultData={defaultData.hobbies} callbackOnChange={callbackOnChange}/>
                        
                    </Paper>
                    
                </Grid>
                </Fade>

                <Grid 
                    item 
                    xs={6} 
                    sx={{ 
                        height: '100%'
                    }}
                >                               
                    <Preview appState={appState} editorState={editorState} handleSave={doSave} defaultData={defaultData.template} callbackOnChange={callbackOnChange}/>
                </Grid>
            </Grid>       
    )
}