import React from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import {
    SimpleUIApp,

    SimpleUIPageUsers
} from './simpleUI'

import ResumeCVEdit from "./pages/edit/"
// import Test from "./pages/edit/Repl/"
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const pages = [
    {
        path: "/",
        Main: ResumeCVEdit,
        sidebarnav: {
            icon: SyncAltIcon,
            label: "Stack Calc."
        }
    },
]

const settings = {
    useAuthentication: false,
    useEmailAsUsername: false,
    hideAppBar: true,
    hideNavBar: true
}

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp pages={pages} settings={settings}/>        
    </React.StrictMode>,
    document.getElementById('root')
)
