// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
} from '@mui/material/'
// --------------------------------------------------------- MUI ICONS --
import {
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import './style.css'
import Editor from './components/editor'
import Guide from './guide'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
// const CVID = "70933841-bfe5-416b-8c77-9d86a16de12a"
// const CVKEY =  "d8870a92e277b9444746d3f3ac"
// ----------------------------------------------------------------------
// --------------------------------------------------------- Vars -------

export default (props) => {             
    const queryParameters = new URLSearchParams(window.location.search)
    const CVID = queryParameters.get("id")
    const CVKEY = queryParameters.get("key")

    const [appState, setAppState] = useState({
        cvId: CVID,
        cvKey: CVKEY,
    })

    const [showGuide, setShowGuide] = useState(true)

    return (
        <>
            {(appState.cvId == null && appState.cvKey == null)        
                ? <Guide appState={appState} setAppState={setAppState} setShowGuide={setShowGuide} /> 
                : <Editor appState={appState} setAppState={setAppState} />      
            }
        </>
    )
}
