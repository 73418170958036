// --------------------------------------------------------- REACT ------
import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Grid,
    Box,

    Typography,

    TextField,
    IconButton,
    Button,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    AccountCircle as AccountCircleIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
import dayjs from 'dayjs'
import 'dayjs/locale/da'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import './style.css'
// ----------------------------------------------------------------------
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
        resolve(event.target.result)
    }
    reader.readAsDataURL(file)
})

export default (props) => {
    const {editorState, setEditorState, defaultData, callbackOnChange} = props        
    
    const formDataDefaults = {
        sectionTitle: "",

        jobTitle: "",
        firstName: "",        
        lastName: "",        
        email: "",
        phone: "",
        city: "",     
        
        birthDate: "",        

        avatar: "",
    }
        
    const [formData, setFormData] = useState(defaultData)

    useEffect(() => {        
        setFormData({...defaultData})
    }, [defaultData])

    useEffect(() => {        
        callbackOnChange("details", {...formData})
    }, [formData])
    
    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
        setFormData({ ...formData, [id]: value })
        return true
    }
    
    const handleUploadAvatar = (event) => {
        const MIN_FILE_SIZE = 0 // 1MB
        const MAX_FILE_SIZE = 5120 // 5MB

        const fileSizeKiloBytes = event.target.files[0].size / 1024

        if(fileSizeKiloBytes < MIN_FILE_SIZE){
            setEditorState({...editorState, error: true, errorText: "File size is less than minimum limit"})
            return
        }
        
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setEditorState({...editorState, error: true, errorText: "File size is greater than maximum limit"})
            return
        }

        fileToDataUri(event.target.files[0])
            .then(dataUri => {
                handleOnChange({target: {
                    id: "avatar",
                    value: dataUri
                }})      
        })
    }

    const handleRemoveAvatar = (event) => {
        handleOnChange({target: {
            id: "avatar",
            value: ""
        }})
    }
   
    return (
        <Grid container spacing={2} direction="row" sx={{mb: 2}}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Personlige detaljer
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2} direction="column" sx={{mb: 2}}>
                    <Grid item xs={6}>
                        <TextField
                            id="firstName"
                            name="firstName"
                            fullWidth                                
                            label="Fornavn"
                            variant="outlined"
                            value={formData.firstName}
                            onChange={handleOnChange}
                            disabled={editorState.disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="lastName"
                            name="lastName"
                            fullWidth                                
                            label="Efternavn"
                            variant="outlined"
                            value={formData.lastName}
                            onChange={handleOnChange}
                            disabled={editorState.disabled}
                        />      
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="birthDate"
                            name="birthDate"
                            fullWidth                                
                            label="Fødselsdato"
                            variant="outlined"
                            value={formData.birthDate}
                            onChange={handleOnChange}
                            disabled={editorState.disabled}
                        />      
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Box display="flex" justifyContent="center" sx={ {alignItems: 'center', height: '200px', width: '200px', border: 1, borderRadius: 2, borderColor: '#bbb' }}>
                    {(formData.avatar)        
                    ? 
                        <Box 
                            component="img"                
                            sx={{                            
                                padding: '10px',
                                maxWidth: '100%',
                                maxHeight: '100%' 
                            }}
                            // onDragStart={preventDragHandler}                        
                            src={formData.avatar}
                        /> 
                    : 
                        <AccountCircleIcon fontSize="large"/>
                    }
                </Box>
                
                {(!formData.avatar)        
                ?
                    <>
                        <input accept="image/*" id="icon-button-file" type="file" style={{display: 'none'}} onChange={handleUploadAvatar}/>
                        <label htmlFor="icon-button-file">
                            <Button component="span">Upload billede</Button>
                        </label>
                    </>
                :
                    <Button component="span" onClick={handleRemoveAvatar}>Fjern billede</Button>
                }
            </Grid>

            <Grid item xs={6}>
                <TextField
                    id="email"
                    fullWidth                                
                    label="E-mail"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleOnChange}
                    disabled={editorState.disabled}
                />     
            </Grid>

            <Grid item xs={6}>
                <TextField
                    id="phone"
                    fullWidth                                
                    label="Telefon"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleOnChange}
                    disabled={editorState.disabled}
                />      
            </Grid>    

                     
                   
            
            {/* <Grid item xs={6}>
                <TextField
                    id="city"
                    fullWidth                                
                    label="By"
                    variant="outlined"
                    value={formData.city}
                    onChange={handleOnChange}
                    disabled={editorState.disabled}
                />        
            </Grid> */}                         
        </Grid>              
    )
}
