import * as React from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// let CrossFadeProps = {
//   components: {
//     in: boolean;
//     component: React.ReactNode;
//   }[];
// };

export default (props) => {
    const {components} = props

    // console.log (components)
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',    
      }}          
    >
      {components.map((component, index) => (
        <Fade key={index} in={component.in}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
            display="flex"
  justifyContent="center"
  alignItems="center"
          >
            {component.component}
          </Box>
        </Fade>
      ))}
    </Box>
  );
};
