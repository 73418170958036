// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useCallback, useEffect, useRef, memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Collapse,
    Fade,

    Typography,

    TextField,

    IconButton,

} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    DragHandle as DragHandleIcon,
    Delete as DeleteIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- QUILL ------
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
// import dayjs, { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/da'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Memomize ---
const TextFieldMemo = memo(TextField)
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const quillModules = {
    toolbar: [          
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
    ],
}
// ----------------------------------------------------------------------
    
export default (props) => {
    const {item, itemIndex, onItemChange, provided} = props
    const isMounted = useRef(false)    
    const [collapsed, setCollapsed] = useState(true)
    const [deleted, setDeleted] = useState(false)
    const [formData, setFormData] = useState(item)

    useEffect(() => {
        if (isMounted.current) {                        
            onItemChange(itemIndex, formData)
        } else {
            if (!formData.title)
                setCollapsed(false)
            isMounted.current = true
        }    
    }, [formData])

    const handleOnChange = useCallback((event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value

        setFormData(prevState => {
            let newState = {...prevState, [id]: value}
            return newState
        })
        
        return true
    }, [])

    const handleOnClickCollaps = () => {
        setCollapsed(!collapsed)
    }

    const handleOnClickDelete = () => {
        setDeleted(true)        
        setTimeout(() => {
            onItemChange(itemIndex, null)
        }, 500)
    }
            
    return (
        <Collapse in={!deleted}>
            <Fade appear={false} in={!deleted}>
                <Grid container spacing={1} sx={{mb: 1}} >           
                    <Grid item xs={12} >  
                        <Box sx={{border: 1, borderRadius: 1, borderColor: 'grey.400', p: 2, backgroundColor: '#ffffff' }}>
                            <Grid container spacing={0} sx={{mt: 0}}>
                                <Grid item xs={8}>                            
                                    <Typography variant='body' noWrap>
                                        {formData.title || '(ikke angivet)'}
                                    </Typography>
                                    <Typography variant='subtitle2' noWrap>
                                        {formData.link}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} justifyContent={"flex-end"}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <IconButton {...provided.dragHandleProps}>
                                            <DragHandleIcon/>
                                        </IconButton>                                
                                        <IconButton onClick={handleOnClickCollaps}>
                                            { collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                                        </IconButton>
                                        <IconButton onClick={handleOnClickDelete}>
                                            <DeleteIcon/>
                                        </IconButton>                            
                                    </Box>
                                </Grid>
                            </Grid>                                                                                
                            <Collapse in={!collapsed}>
                                <Grid container spacing={2} sx={{mt: 0}}>
                                    <Grid item xs={6}>
                                        <TextFieldMemo
                                            id='title'
                                            fullWidth                                                
                                            label="Title"
                                            variant="outlined"
                                            value={formData.title}
                                            onChange={handleOnChange}
                                        />        
                                    </Grid>
                                    <Grid item xs={6}>                           
                                        <TextFieldMemo
                                            id='link'
                                            fullWidth                                                
                                            label="Link"
                                            variant="outlined"
                                            value={formData.link}
                                            onChange={handleOnChange}
                                        /> 
                                    </Grid>                                    
                                </Grid>                                
                            </Collapse> 
                        </Box>
                    </Grid>            
                </Grid>    
            </Fade>   
        </Collapse>                  
    )
}
