// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Paper,
    Collapse,
    Stack,

    Alert,

    Typography,

    TextField,
    Button,
    CircularProgress,

    Container
    

} from '@mui/material/'
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import emailValidator from 'email-validator'

// --------------------------------------------------------- MUI ICONS --
import {
    CheckCircle as CheckCircleIcon,
    Download as DownloadIcon,
    GridView as GridViewIcon,
    Error as ErrorIcon,

} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import {LoadingButton} from '@mui/lab/'
// import Test from './test'
// import './style.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const API_URL = "/api/resume/cv"
// const CVID = "56e92afd-9c91-4d11-ba45-fa7dbd097f79"

// ----------------------------------------------------------------------
// --------------------------------------------------------- Vars -------

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        //    70933841-bfe5-416b-8c77-9d86a16de12a
              
        mask="########-####-####-####-############"
        definitions={{
          '#': /[0-9a-z]/,
        }}
        placeholder={""}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        lazy={true}
      />
    );
  });
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const TextMaskCustom2 = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        //    d8870a92e277b9444746d3f3ac
        mask="##########################"
        definitions={{
          '#':/[0-9a-z\-]/,
        }}
        placeholder={""}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        lazy={true}
      />
    );
  });
  TextMaskCustom2.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default (props) => {       
    const {appState, setAppState, setShowGuide} = props
    const containerRef = React.useRef(null);
    
    const [slides, setSlides] = useState([
        {
            in: true,
            direction: "right",            
        },
        {
            in: true,
            direction: "left",            
        },
        {
            in: true,
            direction: "right",            
        }
    ])

    const [editFormState, setEditFormState] = useState({
        disabled: false,
        buttonDisabled: true,
        id: "",
        key: ""
    })
    
    const [createFormState, setCreateFormState] = useState({
        disabled: false,
        buttonDisabled: true,
        email: ""
    })


    const handleOnCreateFormChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value        

        const newCreateFormState = {...createFormState}
        newCreateFormState[id] = value
        
        if (emailValidator.validate(newCreateFormState.email))
            newCreateFormState.buttonDisabled = false
        else 
            newCreateFormState.buttonDisabled = true

        setCreateFormState(newCreateFormState)
        return true
    }

    const handleOnCreate = () => {
        setSlides([
            {
                in: false,
                direction: "right",            
            },
            {
                in: false,
                direction: "right",            
            },
            {
                in: false,
                direction: "right",            
            }        
        ])

        fetch(API_URL, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify({email: createFormState.email}),
        })
        .then(data=>{                
            return data.json ()
        })  
        .then((data) => {
            // if (data != null)
                // throw new Error (data.error.code)
                setTimeout(()=> {
                    setAppState({...appState, cvId: data.id, cvKey: data.key })
                }, 500)
            
        })
        .catch((error) => {     
            console.log (error)  
            // handleError (error)
        })            
    }

    const handleOnEditFormChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value        
        
        

        const newEditFormState = {...editFormState}
        newEditFormState[id] = value

        if ((newEditFormState.id != "") && (newEditFormState.key != "") && (newEditFormState.id.length == 36) && (newEditFormState.key.length == 26))
            newEditFormState.buttonDisabled = false
        else 
            newEditFormState.buttonDisabled = true

        setEditFormState(newEditFormState)
        return true
    }

    const handleOnEdit = () => {
        
        setSlides([
        {
            in: false,
            direction: "right",            
        },
        {
            in: false,
            direction: "right",            
        },
        {
            in: false,
            direction: "right",            
        }        
    ])

    setTimeout(()=> {
        setAppState({...appState, cvId: editFormState.id, cvKey: editFormState.key })        
    }, 500)

    }

    return (
        <Grid container sx={ { height: '100%' }}>
            <Grid item xs={6} sx={{ height: '100%', overflow: 'auto' }}>    
                <Paper elevation={3} sx={{p: 5, minHeight: 'calc(100vh)' }}  ref={containerRef}>
                    <Slide direction={slides[0].direction} in={slides[0].in} container={containerRef.current} mountOnEnter unmountOnExit>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h4'>
                                    Velkommen til CV værktøjet
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body'>
                                    Du har nu to valg, enten kan du oprettet et helt nyt CV. Eller hvis du allerede har et CV kan du redigere dette.
                                </Typography>         
                            </Grid>                           
                            {/* <Grid item xs={6}>
                                <Button variant="text" fullWidth size="large">
                                    Jeg vil oprettet et nyt CV
                                </Button>
                            </Grid>  
                            <Grid item xs={6}>
                                <Button variant="text" fullWidth size="large">
                                    Jeg vil redigere mit CV
                                </Button>
                            </Grid>                 */}
                        </Grid>
                    </Slide>

                    <Slide direction={slides[1].direction} in={slides[1].in} container={containerRef.current} mountOnEnter unmountOnExit>
                        <Grid container spacing={2} sx={{mt: '35px'}}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Opret nyt CV
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body'>
                                    Indtast din e-mail i feltet herunder og tryk opret, så er du igang.<br/><br/>
                                    Vi sender også en e-mail med et unikt ID og nøgle, hvis du i fremtiden ønsker at redigere dit CV.
                                </Typography>         
                            </Grid>   
                            <Grid item xs={12}>
                                <TextField  
                                    id="email"
                                    label="E-mail"
                                    fullWidth                   
                                    value={createFormState.email}
                                    onChange={handleOnCreateFormChange}
                                />          
                            </Grid>   
                            {/* <Grid item xs={6}>
                                <Button variant="text" fullWidth size="large">
                                    Tilbage
                                </Button>
                            </Grid>   */}
                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth size="large" disabled={createFormState.buttonDisabled} onClick={handleOnCreate}>
                                    Opret
                                </Button>
                            </Grid>                
                        </Grid>
                    </Slide>

                    <Slide direction={slides[2].direction} in={slides[2].in} container={containerRef.current} mountOnEnter unmountOnExit>
                        <Grid container spacing={2} sx={{mt: '35px'}}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Redigere CV
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body'>
                                    Indtast dit CV ID sammen med den tilhørende nøgle, så tager vi den derfra!<br/><br/>
                                    Begge dele har du modtaget på e-mail dengang du oprettede dit CV.
                                </Typography>         
                            </Grid>   
                            <Grid item xs={7}>


                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>ID</InputLabel>
                                    <OutlinedInput
                                        id="id"
                                        name="id"
                                        value={editFormState.id}
                                        inputComponent={TextMaskCustom}
                                        onChange={handleOnEditFormChange}
                                    />
                                </FormControl>


                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel>Nøgle</InputLabel>
                                    <OutlinedInput
                                        id="key"
                                        name="key"
                                        value={editFormState.key}
                                        inputComponent={TextMaskCustom2}
                                        onChange={handleOnEditFormChange}
                                    />
                                </FormControl>     
                            </Grid>
                            <Grid item xs={6}>
                                {/* <Button variant="text" fullWidth size="large">
                                    Tilbage
                                </Button> */}
                            </Grid>  
                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth size="large" disabled={editFormState.buttonDisabled} onClick={handleOnEdit}>
                                    Redigere
                                </Button>
                            </Grid>                                      
                        </Grid>
                    </Slide>
                    {/* <Test></Test> */}

                </Paper>    
            </Grid>
            <Grid item xs={6}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random/?wallpapers)',
                    backgroundImage: 'url(/logo.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'fit',
                    backgroundPosition: 'center',
                }}>            
            </Grid>
      </Grid>             
    )
}

