// --------------------------------------------------------- REACT ------
import * as React from 'react';
import { useState, useCallback, useEffect, useRef, memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Grid,

    Typography,

    Button,

} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Add as AddIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- DAYJS ------
import dayjs from 'dayjs'
import 'dayjs/locale/da'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import './style.css'
import { v4 as uuidv4 } from 'uuid';
import DNDList from "../dnd-list/"
import Launguage from './item'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const SECTIONID = "language"
// ----------------------------------------------------------------------

export default (props) => {     
    const {editorState, defaultData, callbackOnChange} = props 
    const isMounted = useRef(false)    

    const formDataDefaults = {
        sectionTitle: "",
        items: []
    }
    
    const [formData, setFormData] = useState(formDataDefaults)

    useEffect(() => {
        if (isMounted.current) {                        
            setFormData({...defaultData})
        } else {            
            isMounted.current = true
        }    
    }, [defaultData])

    useEffect(() => {
        callbackOnChange(SECTIONID, formData)
    }, [formData])

    const onChangeItems = (items) => {
        if (isMounted.current) {
            let newFormData = {...formData}
            newFormData.items = items
            setFormData(newFormData)
        }
    }

    const handleOnClickAddEmployment = () => {
        let newItems = [...formData.items]

        newItems.push({
            id: uuidv4(),
            language: "",
            level: 0,            
        })

        setFormData({...formData, ["items"]: newItems})
    }
      
    return (
        <Grid container spacing={1} sx={{mb: 2}}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    Sprog
                </Typography>
                <Typography variant="caption">
                    Her kan du anføre dine sprog kundskaber.
                </Typography>
            </Grid>            
            <Grid item xs={12}>  
                <DNDList defaultItems={formData.items} onChange={onChangeItems} component={Launguage}/>                
            </Grid>
            <Grid item xs={12}>                            
                <Button variant="filled" startIcon={<AddIcon/>} sx={{width: '100%', justifyContent: 'left'}} onClick={handleOnClickAddEmployment} >Tilføj et yderligere sprog</Button>
            </Grid>
        </Grid>                         
    )
}
