// --------------------------------------------------------- REACT ------
import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Grid,

    Typography,

} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- QUILL ------
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- Consts -----
const quillModules = {
    toolbar: [          
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
    ],
}     
// ----------------------------------------------------------------------

export default (props) => {
    const {editorState, defaultData, callbackOnChange} = props        
    
    const formDataDefaults = {
        sectionTitle: "",
        text: ""
    }
        
    const [formData, setFormData] = useState(formDataDefaults)

    useEffect(() => {        
        setFormData({...defaultData})
    }, [defaultData])

    useEffect(() => {        
        callbackOnChange("hobbies", {...formData})
    }, [formData])
    
    // const handleOnChange = (event) => {
    //     const id = (event.target.id || event.target.name)        
    //     const value = event.target.value
    //     setFormData({ ...formData, [id]: value })
    //     return true
    // }
   
    return (
        <Grid container spacing={1} sx={{mb: 2}}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>                    
                    Kort om dig
                </Typography>
                <Typography variant="caption">
                    Skriv et par linjer om, hvem du er som person, hvad du interesserer dig for eller andet, der siger lidt mere om dig som privatperson.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ReactQuill theme="snow" modules={quillModules} value={formData.text} onChange={(value) => setFormData({ ...formData, ["text"]: value })} readOnly={editorState.disabled}></ReactQuill>
            </Grid>                        
        </Grid>              
    )
}