// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Menu,
    MenuItem,
    Stack,
    Paper,    
    IconButton,
    Grid,
    DialogContentText,
    Typography,
    Chip
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {    
    Refresh as RefreshIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
    Edit as EditIcon,
    MoreVert as MoreVertIcon,
    Delete as DeleteIcon,
    Person as StatusEnabledIcon,
    PersonOff as StatusDisabledIcon,
    SafetyCheck as StatusNotactivatedIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
import { useAuthState } from '../../components/authProvider'
import SimpleUIListview from '../../components/Listview'
import SimpleUIDrawer from '../../components/Drawer'
import SimpleUIDialogConfirm from '../../components/DialogConfirm'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import FormCreate from './components/FormCreate'
import FormEdit from './components/FormEdit'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_USERS = "/api/users"
// ----------------------------------------------------------------------

function ListviewUsersMenu(params) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        params.onEdit()
        handleClose()
    }        

    const handleDelete = () => {
        params.onDelete()
        handleClose()        
    }    
  
    return (
        <div>                
            <IconButton 
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}            
            >
                <MoreVertIcon />
            </IconButton>        
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >          
                <MenuItem key={1} onClick={handleEdit} >Edit</MenuItem>          
                <MenuItem key={2} onClick={handleDelete} >Delete</MenuItem>          
            </Menu>
        </div>
    )
}

function Users() {
    const {user, accessToken} = useAuthState()

    const columns = [
        { field: 'status', headerName: '', flex: 0.1, storable: false,
            renderCell: params => {
                if (params.value == 0) 
                    return <StatusDisabledIcon/>

                if (params.value == 1)
                    return <StatusEnabledIcon/>

                if (params.value == 2) 
                    return <StatusNotactivatedIcon/>
                
                return 
            }

            // renderCell: (params) => (               
                
            //     if (params.value == 0)
            //         return <StatusDisabledIcon></StatusDisabledIcon>


            //     // (params.value == 0 &&
            //     //     
            //     // )
                
            //     // (params.value == 1 &&
            //     //     <StatusEnabledIcon></StatusEnabledIcon>
            //     // )

                
            // ),
        },        
        { field: 'name', headerName: 'Name', flex: 1 },        
        { field: 'email', headerName: 'Email', flex: 0.5, },
        { field: 'username', headerName: 'Username', flex: 0.5, },        
        { field: 'usergroups', headerName: 'Groups', flex: 0.5, sortable: false,
            renderCell: (params) => (         
                params.value.map((usergroup, index) => (
                    <Chip key={index} sx={{mr: 1}} size={'small'}
                        label={usergroup.name}                                                
                    />
                ))                
            ),
        },
        { field: 'id', headerName: '', flex: 0.5, align: "center", sortable: false,
            renderCell: (params) => (               
                // <Typography>{user.userId}</Typography>
                (user.userId != params.value) &&
                    <ListviewUsersMenu onEdit={handleEdit} onDelete={handleDelete}></ListviewUsersMenu>
            ),
        },  
    ]
    
    const [tableData, setTableData] = useState([])
    const [selectionModel, setSelectionModel] = React.useState([])    

    const [toolbarState, setToolbarState] = React.useState({create: true, delete: false, edit: false, refresh: true})

    const [dialogState, setDialogState] = React.useState({
        open: false,        
        title: "",
        children: "",
        button1Text: "No",
        button2Text: "Yes",      
        onConfirm: null
    })
    
    const [drawerCreateState, setDrawerCreateState] = React.useState(false)
    const [drawerEditState, setDrawerEditState] = React.useState({open: false})
    

    useEffect(() => {
        refresh ()            
    }, [])

    const refresh = () => {
        fetch(API_URL_USERS, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
        })
          .then((data) => data.json())
          .then((data) => setTableData(data))
    }

    const handleCreate = () => {
        setDrawerCreateState({...drawerCreateState, open: true})                          
    }
    
    const handleEdit = () => {
        setDrawerEditState({...drawerEditState, open: true})
    }

    const handleDelete = () => {
        setDialogState({
            open: true,
            title: "Delete user",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Do you really want to delete this user?                
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: () => {
                fetch(API_URL_USERS +"/"+ selectionModel, {
                    method: 'DELETE', 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    },           
                })                
                .then(() => {
                    refresh ()                                    
                })
                .catch((error) => {
                // console.error('Error:', error);
                })
            },
            onDecline: () => {}
        })
    }

    const handleRefresh = () => {
        refresh ()
    }

    const handleSelection = (selection) => {
        setSelectionModel(selection)            
        if (selection.length != 0 && selection[0] != user.userId)
            setToolbarState({ ...toolbarState, delete: true, edit: true })
        else
            setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    const handleClickAway = () => {
        // handleSelection([])
    }

    return (
        <React.Fragment>     
            <SimpleUIDialogConfirm dialogState={dialogState} setDialogState={setDialogState}/>

            <SimpleUIDrawer state={drawerCreateState} setState={setDrawerCreateState} refresh={refresh}>
                <FormCreate/>
            </SimpleUIDrawer>

            <SimpleUIDrawer state={drawerEditState} setState={setDrawerEditState} refresh={refresh} userId={selectionModel}>
                <FormEdit/>
            </SimpleUIDrawer>
            
            <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100%)',
                    width: '100%'
                    }}
                >                
                <Stack spacing={2} direction="row" justifyContent="end" sx={{pb: '20px'}}>                                    
                    <IconButton color="primary" aria-label="create" onClick={handleCreate} disabled={!toolbarState.create}>
                        <PersonAddAlt1Icon />
                    </IconButton>
                    <IconButton color="primary" aria-label="edit" onClick={handleEdit} disabled={!toolbarState.edit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete" onClick={handleDelete} disabled={!toolbarState.delete}>
                        <DeleteIcon />
                    </IconButton>                    
                    <IconButton aria-label="refresh" onClick={handleRefresh} disabled={!toolbarState.refresh}>
                        <RefreshIcon />
                    </IconButton>
                </Stack>
                
                <SimpleUIListview columns={columns} rows={tableData} onSelection={handleSelection} onClickAway={handleClickAway} rowsPerPageOptions={[100]} selectionModel={selectionModel}></SimpleUIListview>

            </Paper>    
        </React.Fragment>
    )
}

export default () => {
    return (        
        <Grid container sx={ { height: '100%' }}>
            <Grid item xs={12}>                
                <Users />
            </Grid>                   
        </Grid>            
    )
}